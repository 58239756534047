<template>
  <v-dialog
    v-model="show"
    width="500"
    persistent
    @click:outside="onCancel"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text v-html="content"></v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              block
              color="primary"
              @click="onOK"
            >
              {{ okBtnText }}
            </v-btn>
          </v-col>
          <v-col v-if="showCancel">
            <v-btn
              block
              outlined
              @click="onCancel"
            >
              {{ cancelBtnText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    okBtnText: {
      type: String,
      default: 'OK',
    },
    cancelBtnText: {
      type: String,
      default: 'Cancel',
    },
  },
  setup(props, { emit }) {
    const show = ref(true)
    const showDialog = () => {
      show.value = true
    }
    const onOK = () => {
      emit('ok')
      show.value = false
    }
    const onCancel = () => {
      emit('cancel')
      show.value = false
    }

    return {
      show,
      showDialog,
      onOK,
      onCancel,
    }
  },
}
</script>
