<template>
  <div>
    <v-card-text class="pa-2">
      <div>
        {{ $t('Fullname') }}:
      </div>
      <div class="font-weight-bold mb-2">
        {{ userData.full_name }}
      </div>
      <div>
        {{ $t('PhoneNumber') }}:
      </div>
      <div class="font-weight-bold mb-2">
        {{ userData.phone }}
      </div>
      <div>
        {{ $t('Gender') }}:
      </div>
      <div class="font-weight-bold mb-2">
        {{ $t(getGender(userData.gender)) }}
      </div>
      <div>
        {{ $t('YearOfBirth') }}:
      </div>
      <div class="font-weight-bold mb-2">
        {{ userData.year_of_birth }}
      </div>
    </v-card-text>

    <v-card-text>
      <v-row justify="space-around">
        <v-col
          cols="6"
          md="3"
          class="text-center"
        >
          <v-avatar size="130">
            <v-img
              :src="userData.face_image_url"
              aspect-ratio="1"
            />
          </v-avatar>
          <div>
            {{ $t('FaceImage') }}
          </div>
        </v-col>
        <v-col
          cols="6"
          md="3"
          class="text-center"
        >
          <v-avatar size="130">
            <v-img
              :src="userData.face_mask_image_url"
              aspect-ratio="1"
            />
          </v-avatar>
          <div>
            {{ $t('FaceWithMaskImage') }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="pa-2">
      <v-row class="mt-1">
        <v-col>
          <v-btn
            color="primary"
            outlined
            block
            @click="$router.push({ name: 'staff-user-reset-password' })"
          >
            {{ $t('ResetPassword') }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            outlined
            color="success"
            block
            @click="$router.push({ name: 'staff-user-update' })"
          >
            {{ $t('UpdateInfo') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-1">
        <v-col>
          <v-btn
            color="error"
            outlined
            block
            @click="isShowDialogLockUser=true"
          >
            {{ !!userData.is_lock ? $t('UnlockUser') : $t('LockUser') }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            color="error"
            outlined
            block
            @click="isShowDialogDeleteUser=true"
          >
            {{ $t('DeleteUser') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <ConfirmDialog
      v-if="isShowDialogDeleteUser"
      :title="$t('AreYouSure')"
      :content="$t('AreYouSureToDeleteUser')"
      @cancel="isShowDialogDeleteUser = false"
      @ok="deleteUser"
    ></ConfirmDialog>
    <ConfirmDialog
      v-if="isShowDialogLockUser"
      :title="$t('AreYouSure')"
      :content="!!userData.is_lock ? $t('AreYouSureToUnlockUser') : $t('AreYouSureToLockUser')"
      @cancel="isShowDialogLockUser = false"
      @ok="updateUserStatus"
    ></ConfirmDialog>
  </div>
</template>

<script>
import { ref, inject } from '@vue/composition-api'
import AccountService from '@/services/AccountService'
import { useRouter } from '@core/utils'
import { getGender } from '@/utils/accountHelper'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import store from '@/store'

export default {
  components: {
    ConfirmDialog,
  },
  setup() {
    const { router } = useRouter()
    const userId = router.currentRoute.params.id
    const isShowDialogDeleteUser = ref(false)
    const isShowDialogLockUser = ref(false)
    const userData = inject('userData', {})
    const getUserData = inject('getUserData', async () => {})

    const deleteUser = async () => {
      try {
        await AccountService.deleteUser(userId)
        isShowDialogDeleteUser.value = false
        router.push({ name: 'staff-users-management' })
        store.commit('snackbar/show', {
          message: 'Delete user successfully',
          type: 'success',
        })
      } catch {}
    }

    const updateUserStatus = async () => {
      try {
        await AccountService.lockUnlockedUser(userId, {
          is_lock: !userData.value.is_lock,
        })
        await getUserData()
        isShowDialogLockUser.value = false
        store.commit('snackbar/show', {
          message: "Update user's status successfully",
          type: 'success',
        })
      } catch {}
    }

    return {
      userData,
      isShowDialogDeleteUser,
      isShowDialogLockUser,
      getGender,
      deleteUser,
      updateUserStatus,
    }
  },
}
</script>
